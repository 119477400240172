<template>
  <div class="blog-detail-wrapper">

    <b-row class="blog-list-wrapper">
      <b-card no-body class="col-md-12">
        <b-card-header class="pb-50">
          <h5>
            Фильтр
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="12"
                md="12"
                class="mb-md-0 mb-2"
            >
              <label>Тип списков</label>
              <v-select
                  v-model="type"
                  :options="typeOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="false"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-overlay
          :show="listLoading"
          rounded="sm"
      >
        <b-col cols="12" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
          <b-table
              :items="listsList"
              responsive
              :fields="tableColumns"
              primary-key="filmId"
              show-empty
              empty-text="No matching records found"
              class="position-relative col-12"
          >

            <!-- Column: List -->
            <template #cell(title)="data">
              <span class="font-weight-bold d-block text-nowrap">
                <b-link class="text-body-heading" :to="{name: 'list-view', params: { id: data.item.id }}">
                  {{ data.item.title }}
                </b-link>
              </span>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-button
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-overlay>
    </b-row>

    <b-overlay
        :show="createItemLoading"
        rounded="sm"
    >
      <b-row class="mb-1">
        <b-col cols="12">
          <b-form-input
              v-model="newListName"
              placeholder="Название списка"
          />
        </b-col>
        <b-col cols="12" class="mt-1">
          <b-button
              type="submit"
              variant="success"
              block
              @click.prevent="createItem(newListName)"
          >
            Добавить
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>

    <!--/ blogs -->

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BOverlay,
  BButton, VBTooltip,
} from 'bootstrap-vue'
import {avatarText, badWord, kFormatter, truncateString} from '@core/utils/filter'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import topicsStoreModule from "@/views/topics/topicsStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      blogSidebar: {},
      disabled: 0,
      newListName: '',
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {},
  setup() {
    const STORE_MODULE_NAME = 'topics'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, topicsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Table Handlers
    const tableColumns = [
      {key: 'id', label: '#', sortable: false},
      {key: 'title', sortable: true},
      {key: 'items_count', sortable: true},
      {key: 'auto', sortable: true},
      {key: 'actions'},
    ]

    const type = ref('music')
    const typeOptions = [
      { label: 'Музыка', value: 'music' },
      { label: 'Фильмы', value: 'movies' },
      { label: 'Сериалы', value: 'series' },
    ]

    const listsList = ref([])
    const createItemLoading = ref(false)
    const listLoading = ref(false)
    const lists = ref([])
    const freeLists = ref([])
    const fetchLists = (...args) => store.dispatch('topics/fetchLists', ...args)
    const addList = (...args) => store.dispatch('topics/createList', ...args)

    const fetchListsList = (type) => {
      listLoading.value = true;
      fetchLists({queryParams: {type: type.value, auto: 0}})
          .then(response => {
            listsList.value = response.data.data
            lists.value = response.data.lists
            freeLists.value = response.data.free_lists
            listLoading.value = false;
          })
    }

    const createItem = (name) => {
      createItemLoading.value = true
      addList({queryParams: {title: name, type: type.value}}).then(response => {
        listsList.value.push(response.data.data)
        createItemLoading.value = false
        this.newListName = ''
      })
    }

    fetchListsList(type)

    watch([type], () => {
      fetchListsList(type)
    }, {
      deep: true,
    })

    return {
      fetchLists,
      fetchListsList,
      tableColumns,
      listsList,
      lists,
      avatarText,
      createItem,
      createItemLoading,
      listLoading,

      typeOptions,
      type,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
