import axios from '@axios'
import axiosRu from '@axios-ru'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTopics(ctx, {queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/autotopic', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLists(ctx, {queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/list', {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createList(ctx, {queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .post('/list', queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateList(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
            .patch(`/list/${id}`, queryParams)
            .then(response => resolve(response))
            .catch(error => reject(error))
      })
    },
    fetchList(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/list/${id}`, {params: queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAutotopic(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/autotopic/${id}`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createAutotopic(ctx, {queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/autotopic`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addItemToList(ctx, {id, queryParams}) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/list/${id}`, queryParams)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
